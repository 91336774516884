import React,{useEffect} from 'react';
import './Life_FSC_V1.scss'
import './Animated.scss';
import Header from "../../Includes/Layouts/Header";
import Footer from '../../Includes/Layouts/Footer';
import Testimonials2 from '../../Includes/Layouts/Testimonials2';
const Thankyou = () =>{

    return (
      <>
      <Header />
            <section class="SECT">
                <div class="container ">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-md-12 col-sm-12 pad0  text-center animated bounceInDown tnku">
                        <img src="/assets/Life_FSC_V1/thumb.png" alt=""></img>
                            <h1 class="animated zoomInUp">Thank you</h1>
                            <p>You've taken the first steps in getting protection. A life insurance consultant will be in touch with you shortly to discuss any additional requirements</p>   
                        </div>
                    </div>
                </div>
            </section>
            <Testimonials2 />
            <Footer/>
        </>
    )
}

export default Thankyou;
