import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";


const Complaints = () => {
    
  return (
    <>
    <div className="" id="complaints" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
  <div className="modal-dialog  modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="">COMPLAINTS</h5><br/>
      </div>
      <div className="modal-body">
      
<p><strong>What to do if you have a complaint</strong></p>
<p>If you have any complaint about the advice or services provided by this firm, you should contact the Complaints Officer either by Mail: Smarter Cover Ltd, 243 Brooklands Road, Weybridge, Surrey, KT13 0RH or E-Mail: compliance@smartercover.co.uk or by Phone: 01420 383091, giving as much information as possible about the nature of your complaint.</p>
<p><strong>What to expect from us when we deal with your complaint<br/></strong></p>
<p>A complaint may be made free of charge. We will acknowledge your complaint within five business days of it being received, unless it can be resolved by close of business on the third business day. If your complaint was made verbally we will include, within that acknowledgement, a copy of our Complaints Handling Leaflet and our understanding as to the nature of your complaint. If we have not resolved your complaint within eight weeks we will write to you explaining the reasons why and indicate when we expect to resolve your complaint.</p>
<p><strong>Handling your case<br/></strong></p>
<p>We will review all documentation available to us to carry out a thorough and objective investigation. In some instances, we may ask you for further information or to provide authority for us to contact third parties, such as Lenders.</p>
<p>We aim to resolve most cases promptly, fairly, with the minimum formality and within eight weeks from when they are referred to us and base our final decision on what we consider fair and reasonable in the circumstances of the case, setting out clearly the reasons for our decision.</p>
<p>We will keep in touch with you regularly while we are looking into your case. We will tell you how things are progressing and what you need to do next. You will always know the name of the person handling your case and you can contact them directly with any questions. We aim to resolve all our cases as quickly as possible, although in a few exceptional cases our investigations may take longer, particularly if we need to make wide ranging enquiries. In some situations we may ask you for further information to assist with our consideration of your case and your prompt attention to these matters would be gratefully appreciated.</p>
<p>If we have not resolved your complaint within eight weeks from the date of receipt, we will write to inform you of the situation and explain the reason for the delay, enclosing the booklet “Your Complaint and the Ombudsman”. We will also inform you when we expect to issue our final response letter and that you may refer the matter to the Financial Ombudsman Service if you are not satisfied with the progress of our investigation.</p>
<p><strong>Taking your views into account, and reaching our decision<br/></strong></p>
<p>We will always take account of what you tell us about the case, as well as that of the adviser who provided the advice. Our role is to clarify what has occurred based on the evidence presented to us. Evidence is the crucial factor, and it is the weight of evidence presented by one or other parties that will assist in our consideration of your case. Complaints are appraised based on the law, the rules and principles of the regulator for the financial services industry that were in force at the time the contract in place was sold. We also take into consideration such factors as your circumstances and requirements at that time. However, any changes in your circumstances since the advice was given, is deemed to be immaterial.</p>
<p>In order for your complaint to succeed <strong>you</strong> must generally demonstrate that the advice provided to you was unsuitable, or that there has been a breach of regulations as stated above. You must also have suffered an actual financial loss, material distress or material inconvenience as a result of any such inappropriate advice or service. If it is established that no such loss, material distress or inconvenience, has occurred we will not award a suitable compensation payment.</p>
<p>We must also draw your attention to the burden of proof, which must be satisfied for your complaint to succeed. We follow the general principle used in English Law that “he who asserts must prove”. This means that any complainant alleging fault, negligence or breach of the rules on the part of one of our advisers has the burden of proving it on the balance of probabilities. In addition, any complainant claiming compensation has the burden of proving that the loss claimed was caused by negligence, fault or breach of the rules. This means that unless we are persuaded that your account of events is more probable than not we must reject your complaint.</p>
<p>At the conclusion of an investigation we will provide you with a final response letter and/or written report, which clearly explains the outcome of the investigation and details of any settlement, which we may consider to be appropriate, or, alternatively, the reasons for declining to offer a settlement.</p>
<p>We will also enclose the leaflet “Your Complaint and the Ombudsman” and inform you that you may refer the matter to the Financial Ombudsman Service if you are not satisfied with the outcome of our investigation and that you must do so within six months of the date of issue of our final response letter notwithstanding that there may have been further correspondence between us.</p>
<p>Where we have determined that you have not been given proper advice, or that we have breached another duty of care, we hope in most circumstances to offer redress that puts you, so far as is practicable, in the position that you would have been in had you been given appropriate advice. When calculating any loss we may take into account any ‘savings’ that you may have enjoyed as a result of the inappropriate advice.</p>
<p><strong>What happens if you disagree with our conclusions?<br/></strong></p>
<p>If you disagree with the view we set out about your case, you should refer back to us in the first instance. We will then consider your comments and advise you whether or not they alter our original conclusion. Should you still not consider the outcome of this investigation to be fair or reasonable you can refer the matter to the Financial Ombudsman Service.</p>
<p>The regulator for the financial services industry set up this organisation to provide customers with a free, independent service for resolving disputes with financial firms. Correspondence should be addressed to the Ombudsman at:-</p>
<p>Financial Ombudsman Service, Exchange Tower, London, E14 9SR. Telephone number, 020 7964 1000. <a rel="noopener" href="http://www.financial-ombudsman.org.uk" target="_blank" title="www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</a>&gt;</p>
<p><strong>Our contacts with you<br/></strong></p>
<p>In all our contact with customers, we aim to treat them fairly, be polite and professional. To help with staff training we may monitor or record telephone calls. Many of our cases involve complex financial and legal issues but we always try to set out our views clearly, without using jargon.</p>
<p><strong>If you are unhappy about the service we have provided<br/></strong></p>
<p>Naturally, we hope you’ll be happy with the service we provide. However, we know that - as in any organisation - things can sometimes go wrong. If you are unhappy with the service we have provided – for example, if you think we have treated you rudely or unfairly, failed to explain things properly, or caused delays, then let us know. We take complaints about our service very seriously. If we get things wrong – it’s important that you tell us so we can try to put matters right. This also helps us to improve our service in the future.</p>



      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Complaints;