import {Api} from '../api/Api';

export const useQuestion = () => {
    
    const question = async (questionId) => {
        const response = await Api.post("/api/v1/questions", {
            questionId
        });

        return response;
    }
    return { question }
}