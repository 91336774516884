import React from 'react';
import { DobYear,DobMonth,DobDay} from '../../Constants/Constants';
import SelectBox from '../UI/SelectBox';


const DateOfBirth = ({
    className,
    backClick,
    validation,
    validationMsg,
    onChange,
    setError,
    trigger,
    getValues,
    slideChange,
    partnerclass
  }) =>{

    const dob_validation = async (e) => {
        let errorFlag = 0;
        let lstDobDayResult;
        let lstDobMonthResult;
        let lstDobYearResult;

        let partDobDayResult;
        let partDobMonthResult;
        let partDobYearResult;
        // DOb Validation
        lstDobDayResult = await trigger("lstDobDay");
        if(lstDobDayResult)
        {
              lstDobMonthResult = await trigger("lstDobMonth");
        }
        else{
          return false;
        }
        if(lstDobMonthResult)
        {
             lstDobYearResult = await trigger("lstDobYear");
        }
        else{
          return false;
        }
        if (
          !lstDobDayResult ||
          !lstDobMonthResult ||
          !lstDobYearResult
        ) {
          errorFlag = 1;
        }

        //   Partner Dob Validation
      if(partnerclass ==="show"){
        partDobDayResult = await trigger("part_DobDay");
        if(partDobDayResult)
        {
            partDobMonthResult = await trigger("part_DobMonth");
        }
        else{
          return false;
        }
        if(partDobMonthResult)
        {
            partDobYearResult = await trigger("part_DobYear");
        }
        else{
          return false;
        }
        if (
          !partDobDayResult ||
          !partDobMonthResult ||
          !partDobYearResult
        ) {
          errorFlag = 1;
        }
      }

        if (errorFlag === 0) {
            slideChange(e);
        } else {
          return false;
        }
      };
    const basic_onchangehandle = async (e) => {
        trigger(e.target.name);
      };

    return (
        <>
        <div className="col-lg-12 form_2">
            <div className="col-lg-12 row custom m-0">
                <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 ">
                    <label className="hdding py-2">Enter your date of birth to see what policies you qualify for </label>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox 
                                    className="form-control"
                                    OptionValue={DobDay}
                                    name = "lstDobDay"
                                    myRef={validation({ required: "Please Select Date"
                                        })} 
                                    validationMsg={validationMsg.lstDobDay && validationMsg.lstDobDay.message}
                                    onChange ={basic_onchangehandle}
                                />
                                <i className="tick fa" style={{display:'none'}}></i>
                                {/* <span id="dobDay_err" className="error_msg"></span> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox 
                                    className="form-control"
                                    OptionValue={DobMonth}
                                    name = "lstDobMonth"
                                    myRef={validation({ required: "Please Select Month"
                                        })} 
                                    validationMsg={validationMsg.lstDobMonth && validationMsg.lstDobMonth.message}
                                    onChange ={basic_onchangehandle}
                                />
                                <i className="tick fa" style={{display:'none'}}></i>
                                {/* <span id="dobMonth_err" className="error_msg"></span> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div className="form-group">
                                <SelectBox 
                                    className="form-control"
                                    OptionValue={DobYear}
                                    name = "lstDobYear"
                                    myRef={validation({ required: "Please Select Year"
                                        })} 
                                    validationMsg={validationMsg.lstDobYear && validationMsg.lstDobYear.message}
                                    onChange ={basic_onchangehandle}
                                />
                                <i className="tick fa"  style={{display:'none'}}></i>
                                {/* <span id="dobYear_err" className="error_msg"></span> */}
                            </div>
                        </div>
                        <span id="dob_final_err" className="error_msg"></span>
                    </div>
                </div>
           {/* Partner section Here */}
           <div className={` ${partnerclass} offset-lg-3 col-lg-6 col-md-12 col-sm-12`} style={{display: "none"}}>
               <label className="hdding py-2"> Enter your partner's day of birth</label>
               <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox 
                            className="form-control"
                            OptionValue={DobDay}
                            name = "part_DobDay"
                            myRef={validation({ required: "Please Select Date"
                                })} 
                            validationMsg={validationMsg.part_DobDay && validationMsg.part_DobDay.message}
                            onChange ={basic_onchangehandle}
                        />
                        <i className="tick fa" style={{display:'none'}}></i>
                        {/* <span id="dobDay_err" className="error_msg"></span> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox 
                            className="form-control"
                            OptionValue={DobMonth}
                            name = "part_DobMonth"
                            myRef={validation({ required: "Please Select Month"
                                })} 
                            validationMsg={validationMsg.part_DobMonth && validationMsg.part_DobMonth.message}
                            onChange ={basic_onchangehandle}
                        />
                        <i className="tick fa" style={{display:'none'}}></i>
                        {/* <span id="dobMonth_err" className="error_msg"></span> */}
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                        <SelectBox 
                            className="form-control"
                            OptionValue={DobYear}
                            name = "part_DobYear"
                            myRef={validation({ required: "Please Select Year"
                                })} 
                            validationMsg={validationMsg.part_DobYear && validationMsg.part_DobYear.message}
                            onChange ={basic_onchangehandle}
                        />
                        <i className="tick fa"  style={{display:'none'}}></i>
                        {/* <span id="dobYear_err" className="error_msg"></span> */}
                    </div>
                </div>
                <span id="dob_final_err" className="error_msg"></span>
            </div>

            </div>
            {/* close the Partner section */}
         </div>
        </div>
        <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center">
            <input type="button" id="" name="" className="next-but next_06 btn my-bt sctop " onClick={dob_validation} value="Continue >>"/>
            <div className="col-12 text-center mt-1">
                <a id="back5" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
            </div>
            <div className="text-center safe-text"> 
                <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
            </div>
            </div>
        </>


    )
}

export {DateOfBirth};