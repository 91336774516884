import React from "react";

const TextField = ({
  name,
  type,
  placeholder,
  className,
  onChange,
  onBlur,
  validation,
  validationMsg,
  onkeypress,
  value,
  maxlength,
  readonly
}) => {
  return (
    <>
      <input
        name={name}
        type="text"
        placeholder={placeholder}
        className={className}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onkeypress}
        ref={validation}
        defaultValue={value}
        maxLength={maxlength}
        readonly ={readonly}
      />
      {validationMsg && (
        <>
          <span className="address1_error error_msg">{validationMsg}</span>
        </>
      )}
    </>
  );
};
export default TextField;
