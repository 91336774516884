import React, { useContext,useEffect } from "react";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import "./Life_FSC_V1.scss";
import {useDataIngestion} from "../../../Hooks/useDataIngestion";
import Form_Life_FSC_V1 from "../../Forms/Form_Life_FSC_V1";
import Testimonials from "../../Includes/Layouts/Testimonials";
import Testimonials2 from "../../Includes/Layouts/Testimonials2";
import Footer from "../../Includes/Layouts/Footer";
import Header from "../../Includes/Layouts/Header";

const Life_FSC_V1 = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const {saveDataIngestion,isLoading}   = useDataIngestion();
  useEffect(() => {
    (async () => {
          await saveDataIngestion(
            visitorParameters.visitor_parameters,
            visitorParameters.data,
            'split_page_load',
            'Life_FSC_V1',
          )
    })();
  }, [visitorParameters]);

  return (
    <>
    <Header />
    <section className="SECT">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-sm-12 pad0">
            <h1 className="d-block d-sm-none d-md-none d-lg-none">Compare <span>Life Insurance</span> Quotes </h1>
            <p className="d-block d-md-none star-t"></p>
            <div className="form_part">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                <span className="start-but">Start<br/><img src="/assets/Life_FSC_V1/arrow-b.png" alt=""/></span>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-block d-sm-block d-md-block d-none">
                <h1 className="">Compare <span>Life Insurance</span> Quotes </h1>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-12 progrss mt-3" style={{display: "none"}}>
                <div className="progress">
                  <div className="progress-bar" id="progressBar" style={{width: "0%", background: "#3ebbe9"}}></div>
                </div>
                <p className="text-center prgrs_txt" id="progressBar_content">Progress 0%</p>
              </div>
              <Form_Life_FSC_V1 />
            </div>
          </div>
        </div>
      </div>
    </section>

  {/* Testimonials Start */}
    <Testimonials />
  {/* Testimonials End */}
    <Testimonials2 />
  {/* Footer Start */}
    <Footer />
  {/* Footer End */}
    </>
  );
};

export default Life_FSC_V1;