import React from "react";

const Telephone = ({ placeholder, name, className, validation, onBlur, onKeyPress }) => {
  return (
    <input  
      type="tel"
      name={name}
      className={className}
      placeholder={placeholder}
      ref={validation}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      onPaste={onKeyPress}
    />
  );
};
export default Telephone;
