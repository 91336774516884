import {Api} from '../api/Api';

export const usePhoneValidation = () => {

    const phoneValidation = async (phone) => {
        const getValidation = await Api.get(
            "api/v1/get-phone-validation?phone=" + phone,
            {}
          );
        return getValidation;
    }
    return { phoneValidation }
}