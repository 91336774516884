const full_url = window.location.href;
const query_string_arr = full_url.split("?");
var queryString = "";
if(query_string_arr!=null)
{
    queryString = query_string_arr[1];
}
if(queryString !== undefined){
   
  }else{
    queryString = "";
  }
export default queryString;