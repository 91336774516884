import React from "react";
const Email = ({ placeholder, name, className, validation, onBlur }) => {
  return (
    <>
      <input
        type="email"
        name={name}
        className={className}
        placeholder={placeholder}
        ref={validation}
        onBlur={onBlur}
      />
    </>
  );
};
export default Email;
