import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";

const Slide3 = ({
  className,
  slideChange,
  classNameChecked10,
  classNameChecked11,
  classNameChecked12,
  classNameChecked13,
  backClick,
  partnerclass
}) => {
 
  return (
    <>

        <div id="slide3" className={className} style={{display: "none"}}>
            <div className="col-lg-12 form_2">
            <div className="col-lg-12 row custom m-0">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
                <p className="hdding py-2"> Have you smoked or used nicotine replacements in the last 12 months? </p>
                <ul className="icon_buttons">
                    <li className="mr js_me">
                        <label for="smoked-yes1" className="next_03 next-but">
                            <div className={classNameChecked10}>
                            <input type="radio"  className="radio-button" name="question5" onClick={slideChange} id="smoked-yes1" value="10"/>
                            <img className="original-image" src="/assets/Life_FSC_V1/smoker_yes_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/smoker_yes_white.png" alt="" /> 
                            </div>
                        </label>
                        <p className="cover_text">YES</p>
                    </li>
                    <li className="mr js_me">
                        <label for="smoked-no1" className="next_03 next-but sctop">
                            <div className={classNameChecked11}>
                            <input type="radio" className="radio-button" name="question5" onClick={slideChange} id="smoked-no1" value="11"/>
                            <img className="original-image" src="/assets/Life_FSC_V1/smoker_no_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/smoker_no_white.png" alt="" />  
                            </div>
                        </label>
                        <p className="cover_text">NO</p>
                    </li>
                </ul>
                </div>
                <div className={partnerclass} style={{display: "none"}}>
                <p className="hdding py-2">Has your partner smoked or used nicotine replacements in the last 12 months? </p>
                <ul className="icon_buttons">
                    <li className="mr js_me">
                        <label for="youknow-yes1" className="next_03 next-but">
                            <div className={classNameChecked12}>
                            <input type="radio"  className="radio-button" name="question6" onClick={slideChange} id="youknow-yes1" value="12"/>
                            <img className="original-image" src="/assets/Life_FSC_V1/smoker_yes_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/smoker_yes_white.png" alt="" /> 
                            </div>
                        </label>
                        <p className="cover_text">YES</p>
                    </li>
                    <li className="mr js_me">
                        <label for="youknow-no1" className="next_03 next-but sctop">
                            <div className={classNameChecked13}>
                            <input type="radio" className="radio-button" name="question6" onClick={slideChange} id="youknow-no1" value="13"/>
                                <img className="original-image" src="/assets/Life_FSC_V1/smoker_no_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/smoker_no_white.png" alt="" /> 
                            </div>
                        </label>
                        <p className="cover_text">NO</p>
                    </li>
                </ul>
                </div>
            </div>
            </div>
            <div className="col-12 text-center mt-1">
            <a id="back2" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
            </div>
            <div className="text-center safe-text"> 
            <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
            </div>
        </div>
    </>
  );
};
export default Slide3;
