//import {useState} from 'react'; 
import {Api} from '../api/Api';

export const usePostCodeAddress = () => {
    //const [isLoading,setIsLoading] = useState(false);
    const getPostcodeValidation = async (postcode) => {
        const response = await Api.get("api/v1/postcode-val?postcode=" + postcode, { 
        });
        return response;
    }
    const getPostcodeAddress = async (postcode) => {
        const response = await Api.get("api/v1/get-addr-list-postcode-api?postcode=" + postcode, { 
        });
        return response;
    }
    const getSplitPostcodeAddress = async (AddressID,postcode) => {
        const response = await Api.get("api/v1/get-addr-split-postcode-api?AddressID=" 
        +AddressID +"&postcode=" +postcode, { 
        });
        return response;
    }
    return { getPostcodeValidation,getPostcodeAddress,getSplitPostcodeAddress}
}

