import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";

const Slide1 = ({
  className,
  slideChange,
  classNameChecked1,
  classNameChecked2
}) => {
 
  return (
    <>


<div id="slide1" className={className}>
    <div className="col-lg-12 form_1">
    <div className="col-lg-12 row custom m-0">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
        <p className="hdding py-2"> I'd like to see quotes for:</p>
        <ul className="icon_buttons">
            <li className="mr js_me" id="testCheckbox" name="testCheckbox">
            <label for="male-yes11" className="next_01 next-but sctop">
                <div className={classNameChecked1}>
                    <input type="radio"  className="radio-button" name="question1" id="male-yes11" onClick={slideChange} value="1"/>
                    <img className="original-image" src="/assets/Life_FSC_V1/me_blue.png" alt="" /> 
                    <img className="active-image" src="/assets/Life_FSC_V1/me.png" alt="" /> 
                </div>
            </label>
            <p className="cover_text" id="checkbox_text">Just Me</p>
            </li>
            <li className="mr">
            <label for="female-no11" className="next_01 next-but sctop">
                <div className={classNameChecked2}>
                    <input type="radio" className="radio-button" name="question1" id="female-no11" onClick={slideChange} value="2"/>
                    <img className="original-image" src="/assets/Life_FSC_V1/partner_blue.png" alt="" />
                    <img className="active-image" src="/assets/Life_FSC_V1/partner.png" alt="" /> 
                </div>
            </label>
            <p className="cover_text">Me & My Partner</p>
            </li>
        </ul>
        </div>
    </div>
    </div>
</div>
    </>
  );
};
export default Slide1;
