import { React, useReducer, useState } from "react";
import TextField from "../UI/TextField";
import Anchor from "../UI/Anchor";
import SelectBox from "../UI/SelectBox";
import HiddenField from "../UI/HiddenField";
import { usePostCodeAddress } from "../../Hooks/usePostCodeAddress";

const initialState = {
  lookUp: true,
  nextClick: false,
  checkSelect: false,
  getDetails: [],
  getAddress: [],
  showManualAddress: "hide",
  getPostcode: "#",
  clickManualLink: 0
}

const postcodeReducer = (state, action) => {
  switch (action.type) {
    case 'validationSucess': {
      return {
        ...state,
        lookUp: action.payload.lookup,
        nextClick: action.payload.next_click,
        getPostcode: action.payload.postcode,
        getAddress: action.payload.get_address,
        checkSelect: action.payload.check_select,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'getExtraAddressDetails': {
      return {
        ...state,
        getDetails: action.payload.get_details,
        showManualAddress: action.payload.manual_address
      }
    }
    case 'showManualDiv': {
      return {
        ...state,
        showManualAddress: "show",
        lookUp:false,
        nextClick:true,
        checkSelect:false,
        clickManualLink: action.payload.manual_click
      }
    }
    default:
      return state;
  }
}

const PostCode = ({
  className,
  validation,
  validationMsg,
  onBackClick,
  clearErrors,
  setError,
  slideChange,
  trigger
}) => {
 
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loaderClass, setLoaderClass] = useState("load hide");

  const checkValidation = async (e) => {
    //validation
    e.preventDefault();
    const txtPostCode = await trigger("txtPostCode");
    if(txtPostCode)
    { 
    setLoaderClass("load show");
    setBtnDisabled(true);
      const getValidation = await getPostcodeValidation(e.target.value)
      if (getValidation.data === 0) {
        setLoaderClass("load hide");
        setBtnDisabled(false);
        setError("txtPostCode", {
          type: "manual",
          message: "Please Enter Valid Postcode",
        });
      }
      if (getValidation.data === 1) {
        const addressList = await getPostcodeAddress(e.target.value)
        if (addressList) {
          setLoaderClass("load hide");
          setBtnDisabled(false);
          dispatch({
            type: 'validationSucess',
            payload: {
              lookup: false,
              next_click: true,
              postcode: e.target.value,
              get_address: addressList.data,
              check_select: true,
              manual_address: "hide",
            }
          });
        }
      }
    }
  };

  const getValue = async (e) => {
    e.preventDefault();
    clearErrors(["address1","txtHouseNumber", "txtAddress3", "txtCounty", "txtTown", "txtCountry"]);
    const getData = await getSplitPostcodeAddress(e.target.value, state.getPostcode)
    dispatch({ type: 'getExtraAddressDetails', payload: { get_details: getData.data, manual_address: "show" } });
  };

  const lookupValidation = async (e) => {
    setLoaderClass("load show");
    setBtnDisabled(true);
    await trigger("txtPostCode");
  }
  const onChangetxtvalidation = async(e)=>{
    e.preventDefault();
    const txtclickresult = await trigger(e.target.name);
    if(txtclickresult){
      clearErrors(e.target.name);
    }
  }
  const addressValidation = async (e) => {
    let errorFlag = 0;
    const txtPostCode = await trigger("txtPostCode");
    const manual_txtHouseNumber = await trigger("txtHouseNumber");
    let manual_txtTown;
    let manual_txtCountry;
    // if (manual_txtHouseNumber) {
    //   manual_txtTown = await trigger("txtTown");
    // }
    // else {
    //   return false;
    // }
    // if (manual_txtTown) {
    //   manual_txtCountry = await trigger("txtCountry");
    // }
    // else {
    //   return false;
    // }
    if (
      !txtPostCode ||
      !manual_txtHouseNumber 
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLink === 0) {
      const address1 = await trigger("address1");
      if (!address1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };

  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetails !== "#" ? state.getDetails.Udprn : ""}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={state.getDetails !== "#" ? state.getDetails.deliverypointsuffix : ""}
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={state.getDetails !== "#" ? state.getDetails.pz_mailsort : ""}
      />
      <HiddenField
       name="txtStreet"
       inputId="txtStreet"
       inputValue={state.getDetails.Line3 !== "#" ? state.getDetails.Line3 : ""}
      />

      {/* yyyyy */}
       <HiddenField
       name="txtAddress3"
       inputId="txtAddress3"
       inputValue={state.getDetails.Line2 !== "#" ? state.getDetails.Line2 : ""}
      />
       <HiddenField
       name="txtCounty"
       inputId="txtCounty"
       inputValue={state.getDetails.County !== "#" ? state.getDetails.County : ""}
      />
       <HiddenField
       name="txtTown"
       inputId="txtTown"
       inputValue={state.getDetails.Town !== "#" ? state.getDetails.Town : ""}
      />
       <HiddenField
       name="txtCountry"
       inputId="txtCountry"
       inputValue={state.getDetails.Country !== "#" ? state.getDetails.Country : ""}
      />
    </>
  );

  return (
    <>
     
    <div id="slide5" className={className} style={{display: "none"}}>
      <div className="col-lg-12 form_2">
        <div className="col-lg-12 row custom m-0">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
                <p className="hdding py-2">Where do you live? </p>
                <div className="offset-lg-3 col-lg-6 col-md-8 offset-md-2 col-12 pass">
                    <div className="form-group">
                      <TextField
                          placeholder="Your Postcode"
                          name="txtPostCode"
                          className="form-control  hero-input"
                          dataId="txtPostCode"
                          onBlur={checkValidation}
                          onChange={()=>{dispatch({
                            type: 'validationSucess',
                            payload: {
                              lookup: true,
                              next_click: false,
                              check_select: false,
                              manual_address: "hide",
                              get_address: []
                            }
                          })}}
                          autoComplete="off"
                          validation={validation({ required: "Please Enter Postcode",
                                                    pattern: {
                                                      value: /^(?:[A-Za-z]+)(?:[A-Za-z0-9\s]*)$/,
                                                      message: "Please Enter a Valid Postcode",
                                                    },
                                                    minLength: {
                                                      value: 5,
                                                      message: "Please Enter a Valid Postcode",
                                                    },
                                                  })}
                          validationMsg={
                            validationMsg.txtPostCode && validationMsg.txtPostCode.message
                          }
                      />
                      {/* <i className="tick fa validate success1 fa-check"></i>
                      <span id="post_err" className="error_msg"></span> */}
                     
                    </div>
                    <div id="currentAddressCollapse"  style={state.checkSelect ? { display: "block" } : { display: "none" }}>
                      <div className="form-group">
                        <SelectBox
                          className="form-control efct"
                          OptionValue={state.getAddress}
                          name="address1"
                          onChange={getValue}
                          myRef={validation({ required: "Please select address" })}
                          validationMsg={
                            validationMsg.address1 && validationMsg.address1.message
                          }
                        />
                        <span id="address1_error" className="error_msg"></span>
                        <i className="validate tick fa" aria-hidden="true" style={{display: "none"}}></i>
                        <div className="error_msg "></div>
                      </div>
                      <div className={`form-group ${state.showManualAddress} `} >
                        <div className="form-group">
                          <TextField
                            placeholder=" House Number/Name"
                            name="txtHouseName"
                            className="form-control"
                            dataId="txtHouseName"
                            autoComplete="off"
                            validation={validation()}
                            validationMsg={
                              validationMsg.txtHouseName && validationMsg.txtHouseName.message
                            }
                            value={state.getDetails.Company}
                            readonly = "readonly"
                          />
                          <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                          ></i>
                          <span id="addr_err" className="error_msg"></span>
                      </div>
                      <div className="form-group required-field">
                          <TextField
                            placeholder="Street Address"
                            name="txtHouseNumber"
                            className="form-control  hero-input"
                            dataId="txtHouseNumber"
                            autoComplete="off"
                            validation={validation()}
                            validationMsg={
                              validationMsg.txtHouseNumber &&
                              validationMsg.txtHouseNumber.message
                            }
                            onChange={onChangetxtvalidation}
                            value={state.getDetails.Line1}
                            readonly = "readonly"
                          />
                          <i
                            className="validate validate_success"
                            aria-hidden="true"
                            style={{ display: "none" }}
                          ></i>
                        </div>
                        {Object.keys(state.getDetails).length > 0 ? <HiddenFields /> : null}
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center" id="postbtndiv" style={state.lookUp ? { display: "block" } : { display: "none" }}>
        <button id="postcodevalid" style={{float: "none"}} disabled={btnDisabled} className="next-but next_05 btn my-bt sctop" onClick={checkValidation} type="button">Lookup Address</button> 
      </div>
      <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center">
        <input
          style={state.nextClick ? { display: "block" } : { display: "none" }}
          className="next-but next_05 btn my-bt sctop"
          value="Continue >>"
          type="button"
          onClick={addressValidation}
        />
        {/* <input type="button"  className="" onClick={addressValidation} value="Continue >>"/> */}
        <div className="col-12 text-center mt-1">
            <a id="back4" className="back_btn sctop" onClick={onBackClick} ><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
        </div>
        <div className="text-center safe-text"> 
            <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
        </div>
      </div>
    </div>
  </>
  );
};
export default PostCode;
