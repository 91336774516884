import React from "react";
import SelectBox from "../../UI/SelectBox";
import TextField from "../../UI/TextField";
import { Salutation } from "../../../Constants/Constants";
import { PartnerSalutation } from "../../../Constants/Constants";

const BasicDetails = ({
  className,
  backClick,
  validation,
  validationMsg,
  setError,
  trigger,
  getValues,
  slideChange,
  partnerclass
}) => {

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    const values = getValues();
    const txtFName = values['txtFName'];
    const txtLName = values['txtLName'];
    let lstSalutationResult = await trigger("lstSalutation");
    let txtFNameResult;
    let txtLNameResult;
    let partSalutationResult;
    let txtPFNameResult;
    let txtpLNameResult;

    if(lstSalutationResult)
    {
       txtFNameResult = await trigger("txtFName");
    }
    else{
      window.scrollTo(0, 0)
      return false;
    }
    if(txtFNameResult)
    {
      if((txtFName.trim() =="") || (txtFName.trim().length==0) || (txtFName.trim().length<3)){
        setError("txtFName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        window.scrollTo(0, 0)
        return false;
      }else{
        txtLNameResult = await trigger("txtLName");
      }
    }
    else{
      window.scrollTo(0, 0)
      return false;
    }
    if(txtLNameResult)
    {
      if((txtLName.trim() =="") || (txtLName.trim().length==0) || (txtLName.trim().length < 2)){
        setError("txtLName", {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
        // window.scrollTo(0, 0)
        return false;
      }
    }
    else{
      return false;
    }
    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult
    ) {
      errorFlag = 1;
    }

// partner section 
if(partnerclass ==="show"){
  const values = getValues();
  const txtpFName = values['txtPFName'];
  const txtpLName = values['txtPLName'];

  let partSalutationResult = await trigger("partSalutation");
    if(partSalutationResult)
    {
      txtPFNameResult = await trigger("txtPFName");
    }
    else{
      // window.scrollTo(0, 0)
      return false;
    }
    if(txtPFNameResult)
    {
      if((txtpFName.trim() =="") || (txtpFName.trim().length==0) || (txtFName.trim().length<3)){
        setError("txtPFName", {
          type: "manual",
          message: "Partner Name is Invalid. Please Recheck",
        });
        // window.scrollTo(0, 0)
        return false;
      }else{
        txtpLNameResult = await trigger("txtPLName");
      }
    }
    else{
      // window.scrollTo(0, 0)
      return false;
    }
    if(txtpLNameResult)
    {
      if((txtpLName.trim() =="") || (txtpLName.trim().length==0) || (txtLName.trim().length < 2)){
        setError("txtPLName", {
          type: "manual",
          message: "Partner Last Name is Invalid. Please Recheck",
        });
        // window.scrollTo(0, 0)
        return false;
      }
    }
    else{
      return false;
    }
  }
// close the Partner section here
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };
 
  const basic_onchangehandle = async(e)=>{
    trigger(e.target.name)
  }

  const namevalidate = async (e) => {
  //  console.log(e.target.value);
   if((e.target.value.trim() =="") || (e.target.value.trim().length==0) )
      setError(e.target.name, {
        type: "manual",
        message: "Your Name is Invalid. Please Recheck",
      });
      return false;
  }

  return (
    <>


<div className="col-lg-12 form_2">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <label className="mobpad10 hdding  py-2">Who is this policy for? </label>
                <div className="row">
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                    <SelectBox
                        className="form-control"
                        OptionValue={Salutation}
                        name="lstSalutation"
                        myRef={validation({ required: "Please Select Salutation" })}
                        validationMsg={
                          validationMsg.lstSalutation &&
                          validationMsg.lstSalutation.message
                        }
                        onChange ={basic_onchangehandle}
                      ></SelectBox>
                      
                    </div>
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                    <TextField
                            type="text"
                            className="form-control hero-input"
                            placeholder="First Name"
                            name="txtFName"
                            validation={validation({
                              required: "Please Enter First Name",
                              minLength: {
                                value: 3,
                                message: "Please Enter Valid First Name",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Your Name is Invalid. Please Recheck",
                              },
                            })}
                            validationMsg={
                              validationMsg.txtFName && validationMsg.txtFName.message
                            }
                            onChange ={basic_onchangehandle}
                            onBlur={namevalidate}
                          ></TextField>
                    </div>
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                    <TextField
                            type="text"
                            className="form-control hero-input"
                            placeholder="Surname"
                            name="txtLName"
                            validation={validation({
                              required: "Please Enter Surname",
                              minLength: {
                                value: 2,
                                message: "Please Enter Valid Surname",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Your Surname is Invalid. Please Recheck",
                              },
                            })}
                            validationMsg={
                              validationMsg.txtLName && validationMsg.txtLName.message
                            }
                            onChange ={basic_onchangehandle}
                            onBlur={namevalidate}
                          ></TextField>
                    </div>
                </div>
            </div>
            <div className={` ${partnerclass} col-lg-12 col-md-12 col-sm-12 text-center`} style={{display: "none"}}>
                <label className="mobpad10 hdding  py-2">Your partner's details </label>
                <div className="row">
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                      <SelectBox
                        className="form-control"
                        OptionValue={PartnerSalutation}
                        name="partSalutation"
                        myRef={validation({ required: "Please Select Salutation" })}
                        validationMsg={
                          validationMsg.partSalutation &&
                          validationMsg.partSalutation.message
                        }
                        onChange ={basic_onchangehandle}
                      ></SelectBox>
                       
                    </div>
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                    <TextField
                            type="text"
                            className="form-control hero-input"
                            placeholder="Partner's First Name"
                            name="txtPFName"
                            validation={validation({
                              required: "Please Enter First Name",
                              minLength: {
                                value: 3,
                                message: "Please Enter Valid First Name",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Your Name is Invalid. Please Recheck",
                              },
                            })}
                            validationMsg={
                              validationMsg.txtPFName && validationMsg.txtPFName.message
                            }
                            onChange ={basic_onchangehandle}
                            onBlur={namevalidate}
                          ></TextField>
                    </div>
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 sizer2 form-group">
                    <TextField
                            type="text"
                            className="form-control hero-input"
                            placeholder="Partner's Last Name"
                            name="txtPLName"
                            validation={validation({
                              required: "Please Enter Surname",
                              minLength: {
                                value: 2,
                                message: "Please Enter Valid Surname",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Your Surname is Invalid. Please Recheck",
                              },
                            })}
                            validationMsg={
                              validationMsg.txtPLName && validationMsg.txtPLName.message
                            }
                            onChange ={basic_onchangehandle}
                            onBlur={namevalidate}
                          ></TextField>
                    </div>
                </div>
            </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center">
            <input
                name=""
                onClick={basicDetailsValidation}
                className="next-but next_07 btn my-bt sctop "
                value="Continue >>"
                type="button"
              />

            <div className="col-12 text-center mt-1">
                <a id="back6" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
            </div>
            <div className="text-center safe-text"> 
                <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
            </div>
            </div>
    </>
  );
};
export default BasicDetails;
