import React, { useEffect, useState } from "react";
import { HashRouter, Route ,BrowserRouter  } from "react-router-dom";
import * as EnvConstants from "./Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { Api } from "./api/Api";
import Life_FSC_V1 from "./Components/Pages/Splits/Life_FSC_V1";
import PrivacyPolicy from "./Components/Pages/Splits/PrivacyPolicy";
import Cookies from "./Components/Pages/Splits/Cookies";
import Complaints from "./Components/Pages/Splits/Complaints";
import Terms from "./Components/Pages/Splits/TermsConditions";
import Thankyou from "./Components/Pages/Splits/Thankyou";
import queryString from "./Utility/QueryString";

const App = () => {
 
  const [visitorParameters, setVisitorParameters] = useState({});
  const referrer = document.referrer;
  useEffect(() => {   
    if(EnvConstants.AppEnv == 'prod') {
      //matomo
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(["setCookieDomain", "*.form.smartercover.co.uk"]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://matomo.spicy-tees.in/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '32']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    }
    
    document.title = EnvConstants.AppTitle;
 
    (async () => {
      const response = await Api.post("api/v1/user-agent", {
        user_agent: window.navigator.userAgent,
        page_type: "LP",
        query_string: queryString,
        domain_name: EnvConstants.AppDomain,
        existingdomain: window.location.href,
        referer_site: referrer,
      });
      const visitorData = response.data.response.visitor_parameters;
      const DataParams = response.data.response.data;
      setVisitorParameters({
        visitor_parameters: visitorData,
        data: DataParams,
      });
    })();
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <BrowserRouter >
            <Route exact path={["/", "/Life_FSC_V1"]} component={Life_FSC_V1} />
            <Route exact path={["/thankyou", "/Life_FSC_V1/thankyou"]} component={Thankyou} />
            <Route exact path={["/privacy-policy", "/Life_FSC_V1/privacy-policy"]} component={PrivacyPolicy} />
            <Route exact path={["/cookie-policy", "/Life_FSC_V1/cookie-policy"]} component={Cookies} />
            <Route exact path={["/complaints", "/Life_FSC_V1/complaints"]} component={Complaints} />
            <Route exact path={["/termsconditions", "/Life_FSC_V1/termsconditions"]} component={Terms} />
          </BrowserRouter >
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};
export default App;
