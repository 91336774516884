import React, { useEffect, useState, useRef, useReducer, useContext  } from "react";
import { DateOfBirth } from "../Includes/DateOfBirth";
import PostCode from "../Includes/PostCode";
import "../Pages/Splits/Life_FSC_V1.scss";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {useDataIngestion} from "../../Hooks/useDataIngestion"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import BasicDetails from "../Includes/Layouts/BasicDetails";
import ContactDetails from "../Includes/Layouts/ContactDetails";
import Slide1 from "../Includes/Layouts/Slide1";
import Slide2 from "../Includes/Layouts/Slide2";
import Slide3 from "../Includes/Layouts/Slide3";
import Slide4 from "../Includes/Layouts/Slide4";


const initialState = {
    slide1: "show",
    slide2: "hide",
    slide3: "hide",
    slide4: "hide",
    slide5: "hide",
    slide6: "hide",
    slide7: "hide",
    slide8: "hide",
    partnerclass : "hide",
    progressclass : "hide",
}
  
  const Life_FSC_V1Reducer = (state, action) => {
    switch (action.type) {
      case 'slide1': {
        let vmoCustomUrl  = 'slide-2';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide1: action.payload.slide1,
          slide2: action.payload.slide2,
          partnerclass: action.payload.partnerclass,
          progressclass: action.payload.progressclass,
        }
      }
      case 'backslide1': {
        return {
          ...state,
          slide1: action.payload.slide1,
          slide2: action.payload.slide2,
          progressclass: action.payload.progressclass,
        }
      }
      case 'slide2': {
        let vmoCustomUrl  = 'slide-3';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide2: action.payload.slide2,
          slide3: action.payload.slide3,
        }
      }
      case 'backslide2': {
        return {
          ...state,
          slide2: action.payload.slide2,
          slide3: action.payload.slide3
        }
      }
      case 'slide3': {
        let vmoCustomUrl  = 'slide-4';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide3: action.payload.slide3,
          slide4: action.payload.slide4
        }
      }
      case 'backslide3': {
        return {
          ...state,
          slide3: action.payload.slide3,
          slide4: action.payload.slide4
        }
      }
      case 'slide4': {
        let vmoCustomUrl  = 'slide-5';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide4: action.payload.slide4,
          slide5: action.payload.slide5
        }
      }
      case 'backslide4': {
        return {
          ...state,
          slide4: action.payload.slide4,
          slide5: action.payload.slide5
        }
      }
  
      case 'slide5': {
        let vmoCustomUrl  = 'slide-6';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide5: action.payload.slide5,
          slide6: action.payload.slide6
        }
      }
      case 'backslide5': {
        return {
          ...state,
          slide5: action.payload.slide5,
          slide6: action.payload.slide6
        }
      }
      case 'slide6': {
        let vmoCustomUrl  = 'slide-7';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide6: action.payload.slide6,
          slide7: action.payload.slide7
        }
      }
      case 'backslide6': {
        return {
          ...state,
          slide6: action.payload.slide6,
          slide7: action.payload.slide7
        }
      }
      case 'slide7': {
        let vmoCustomUrl  = 'slide-8';
        vmoupload(vmoCustomUrl);
        return {
          ...state,
          slide7: action.payload.slide7,
          slide8: action.payload.slide8
        }
      }
      case 'backslide7': {
        return {
          ...state,
          slide7: action.payload.slide7,
          slide8: action.payload.slide8
        }
      }
      default:
        return state;
    }
  }

// VMO Upload
const vmoupload =(vmoCustomUrl)=>{
  let protocol = window.location.protocol;
  let slashes = protocol.concat("//");
  let host = slashes.concat(window.location.host);
  let pathname  = window.location.pathname;
  if((pathname ==='/')||(pathname =='/')||(pathname =='')){
    pathname = '/';
  }else{
    pathname  = window.location.pathname+'/';
  }
  let weburl = host.concat(pathname);
  let VmoPassUrl  = weburl+vmoCustomUrl;

  window.VWO = window.VWO || [];
  window.VWO.push(['activate', {
    customUrl: VmoPassUrl
  }]);
  return true;
}

const Form_Life_FSC_V1 = () => {
  const history = useHistory();
  const {saveDataIngestion,isLoading}   = useDataIngestion();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loaderClass, setLoaderClass] = useState("load hide");
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const splitForm = useRef(null);
  const progressBar = useRef(null);
  const progressBarContent = useRef(null);
  const [checked1, setChecked1] = React.useState();
  const [checked2, setChecked2] = React.useState();
  const [checked3, setChecked3] = React.useState();
  const [checked4, setChecked4] = React.useState();
  const [checked5, setChecked5] = React.useState();
  const [checked6, setChecked6] = React.useState();
  const [checked7, setChecked7] = React.useState();
  const [checked8, setChecked8] = React.useState();
  const [checked9, setChecked9] = React.useState();
  const [checked10, setChecked10] = React.useState();
  const [checked11, setChecked11] = React.useState();
  const [checked12, setChecked12] = React.useState();
  const [checked13, setChecked13] = React.useState();
  
const [state, dispatch] = useReducer(Life_FSC_V1Reducer, initialState);

const formSubmit = async () => {
  setLoaderClass("load show");
  setBtnDisabled(true);
  const form = splitForm.current;
  const values = getValues();
  const formData = values;

  const question1  = form['question1'].value;
  const question2  = form['question2'].value;
  const question3  = form['question3'].value;
  const question4  = form['question4'].value;
  const question5  = form['question5'].value;
  const question6  = form['question6'].value;
  const question7  = form['question7'].value;
  const question8  = form['question8'].value;
  if(formData.address1 != ""){ 
      const txtUdprn = form["txtUdprn"].value;
  const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
  const txtPz_mailsort = form["txtPz_mailsort"].value;
  const txtStreet =  form["txtStreet"].value;
  const txtAddress3 =  form["txtAddress3"].value;
  const txtCounty =  form["txtCounty"].value;
  const txtTown =  form["txtTown"].value;
  const txtCountry =  form["txtCountry"].value;
 


  formData.txtUdprn = txtUdprn;
  formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
  formData.txtPz_mailsort = txtPz_mailsort;
  formData.txtStreet = txtStreet;
  formData.txtAddress3 = txtAddress3;
  formData.txtCounty  = txtCounty;
  formData.txtTown    = txtTown;
  formData.txtCountry = txtCountry;

  }
  
  formData.page_name = 'Life_FSC_V1';
  formData.question1=question1;
  formData.question2=question2;
  formData.question3=question3;
  formData.question4=question4;
  formData.question5=question5;
  formData.question6=question6;
  formData.question7=question7;
  formData.question8=question8;
  
  if (
    visitorParameters.data !== null ||
    visitorParameters.data !== undefined
  ) {
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      'split_form_submit',
      'Life_FSC_V1',
      queryString,
      visitorParameters.data
    )
 
    if (formSUbmitResult.data.status === "Success") {
      history.push(
            "/thankyou"
          );
      // if (queryString != null) {
      //   if (queryString.indexOf("&" + field + "=") !== -1) {
      //     history.push("/fbpixel");
      //   } else {
      //     history.push(
      //       "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
      //     );
      //   }
      // } else {
      //   history.push(
      //     "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
      //   );
      // }
    }
  }
};




const slide1=(e)=>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
    if(e.target.value == 2) {
      setChecked2(true);
      setChecked1(false);
      progressBar.current.style.width = "12%"
      progressBarContent.current.innerHTML = "Progress 12%"
      return dispatch({
        type:'slide1',
        payload: {
          slide2: "show",
          slide1: "hide",
          partnerclass: "show",
          progressclass: "show"
        }
      });
    } else {
      setChecked1(true);
      setChecked2(false);
      progressBar.current.style.width = "12%"
      progressBarContent.current.innerHTML = "Progress 12%"
      return dispatch({
        type:'slide1',
        payload: {
          slide2: "show",
          slide1: "hide",
          partnerclass: "hide",
          progressclass: "show"
        }
      });
    }    
  }

  const backslide1=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "0%"
  progressBarContent.current.innerHTML = "Progress 0%"
    return dispatch({
      type:'backslide1',
      payload: {
        slide2: "hide",
        slide1: "show",
        progressclass: "hide"
      }
    });
  }

  const slide2=(e)=>{

    const form = splitForm.current;
    const question2  = form['question2'].value;
    const question3  = form['question3'].value;
    const question4  = form['question4'].value;
    if(e.target.value == 3) {
      setChecked3(true);
      setChecked4(false);
    } else if(e.target.value == 4) {
      setChecked3(false);
      setChecked4(true);
    }
    
    if(e.target.value == 5) {
      setChecked5(true);
      setChecked6(false);
    } else if(e.target.value == 6) {
      setChecked5(false);
      setChecked6(true);
    }

    if(e.target.value == 7) {
      setChecked7(true);
      setChecked8(false);
      setChecked9(false);
    } else if(e.target.value == 8) {
      setChecked7(false);
      setChecked8(true);
      setChecked9(false);
    } else if(e.target.value == 9) {
      setChecked7(false);
      setChecked8(false);
      setChecked9(true);
    }
    if(question2 && question3 && question4 != "") {
      window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "24%"
  progressBarContent.current.innerHTML = "Progress 24%"
      return dispatch({
        type:'slide2',
        payload: {
          slide3: "show",
          slide2: "hide",
        }
      });
    }
  }

  const backslide2=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "12%"
  progressBarContent.current.innerHTML = "Progress 12%"
    return dispatch({
      type:'backslide2',
      payload: {
        slide3: "hide",
        slide2: "show",
      }
    });
  }

  const slide3=(e)=>{
    const form = splitForm.current;
    const question5  = form['question5'].value;
    const question6  = form['question6'].value;
    if(e.target.value == 10) {
      setChecked10(true);
      setChecked11(false);
    } else if(e.target.value == 11) {
      setChecked10(false);
      setChecked11(true);
    }

    if(e.target.value == 12) {
      setChecked12(true);
      setChecked13(false);
    } else if(e.target.value == 13) {
      setChecked12(false);
      setChecked13(true);
    }

    if(state.partnerclass == "show") { 
      if((question6 == 12 || question6 == 13) && (question5 == 10 || question5 == 11)){
        window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "36%"
  progressBarContent.current.innerHTML = "Progress 36%"
        return dispatch({
          type:'slide3',
          payload: {
            slide4: "show",
            slide3: "hide",
          }
        });
      }
    } else {
      window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "36%"
  progressBarContent.current.innerHTML = "Progress 36%"
      return dispatch({
        type:'slide3',
        payload: {
          slide4: "show",
          slide3: "hide",
        }
      });
    }
   
  }

  const backslide3=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "24%"
  progressBarContent.current.innerHTML = "Progress 24%"
    return dispatch({
      type:'backslide3',
      payload: {
        slide4: "hide",
        slide3: "show",
      }
    });
  }

  const slide4=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "48%"
  progressBarContent.current.innerHTML = "Progress 48%"
    return dispatch({
      type:'slide4',
      payload: {
        slide5: "show",
        slide4: "hide",
      }
    });
  }

  const backslide4=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "36%"
  progressBarContent.current.innerHTML = "Progress 36%"
    return dispatch({
      type:'backslide4',
      payload: {
        slide5: "hide",
        slide4: "show",
      }
    });
  }

  const slide5=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "60%"
  progressBarContent.current.innerHTML = "Progress 60%"
    return dispatch({
      type:'slide5',
      payload: {
        slide6: "show",
        slide5: "hide",
      }
    });
  }

  const backslide5=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "48%"
  progressBarContent.current.innerHTML = "Progress 48%"
    return dispatch({
      type:'backslide5',
      payload: {
        slide6: "hide",
        slide5: "show",
      }
    });
  }

  const slide6=(e)=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "72%"
  progressBarContent.current.innerHTML = "Progress 72%"
    return dispatch({
      type:'slide6',
      payload: {
        slide7: "show",
        slide6: "hide",
      }
    });
  }

  const backslide6=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "60%"
  progressBarContent.current.innerHTML = "Progress 60%"
    return dispatch({
      type:'backslide6',
      payload: {
        slide7: "hide",
        slide6: "show",
      }
    });
  }

  const slide7=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "84%"
  progressBarContent.current.innerHTML = "Progress 84%"
    return dispatch({
      type:'slide7',
      payload: {
        slide8: "show",
        slide7: "hide",
      }
    });
  }

  const backslide7=()=>{
    window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  progressBar.current.style.width = "72%"
  progressBarContent.current.innerHTML = "Progress 72%"
    return dispatch({
      type:'backslide7',
      payload: {
        slide8: "hide",
        slide7: "show",
      }
    });
  }
  
  const basic_onchangehandle = async(e)=>{
    trigger(e.target.name)
  }

  
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  return (
    <>
    <form
     ref={splitForm}
     name="split_form"
     id="user_form"
     method="POST"
     >
        <div className={`${state.progressclass} col-lg-12 progrss mt-3`} style={{display: "none"}}>
          <div className="progress">
            <div className="progress-bar" ref={progressBar} id="progressBar" style={{width: "0%", background: "#3ebbe9"}}></div>
          </div>
          <p className="text-center prgrs_txt" ref={progressBarContent} id="progressBar_content">Progress 0%</p>
        </div>
        {/* Slide 1 Start */}
          <Slide1 
          className={` ${state.slide1}`}
          slideChange={slide1}
          classNameChecked1={` ${checked1 ? "checked" : ""} icon_butn smoker4 `}
          classNameChecked2={` ${checked2 ? "checked" : ""} icon_butn smoker4 `}
          />
        {/* Slide 1 End */}
        
        {/* Slide 2 Start */}
          <Slide2 
          className={` ${state.slide2}`}
          slideChange={slide2}
          classNameChecked3={` icon_butn2 smoker41 ${checked3 ? "checked2" : ""}`}
          classNameChecked4={`icon_butn2 smoker41  ${checked4 ? "checked2" : ""}`}
          classNameChecked5={` ${checked5 ? "checked" : ""} icon_butn smoker2`}
          classNameChecked6={` ${checked6 ? "checked" : ""} icon_butn smoker2 `}
          classNameChecked7={` ${checked7 ? "checked2" : ""} icon_butn2 smoker42`}
          classNameChecked8={` ${checked8 ? "checked2" : ""} icon_butn2 smoker42 `}
          classNameChecked9={` ${checked9 ? "checked2" : ""} icon_butn2 smoker42 `}
          backClick={backslide1}
          />
        {/* Slide 2 End */}
        
        {/* Slide 3 Start */}
        <Slide3
         className={` ${state.slide3}`}
         slideChange={slide3}
         classNameChecked10={` ${checked10 ? "checked" : ""} icon_butn smoker5`}
         classNameChecked11={` ${checked11 ? "checked" : ""} icon_butn smoker5`}
         classNameChecked12={` ${checked12 ? "checked" : ""} icon_butn smoker6`}
         classNameChecked13={` ${checked13 ? "checked" : ""} icon_butn smoker6`}
         backClick={backslide2}
         partnerclass = {` ${state.partnerclass} col-lg-12 col-md-12 col-sm-12 col-12 txt-lft `}
        />
        {/* Slide 3 End */}
        
        {/* Slide 4 Start */}
        <Slide4 
        className={` ${state.slide4}`}
        slideChange={slide4}
        backClick={backslide3}
        />
        {/* Slide 4 End */}
        
        {/* Slide 5 Start */}
        
          <PostCode
              className={` ${state.slide5}`}
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              clearErrors={clearErrors}
              setError={setError}
              textContent="Your Current Address"
              onBackClick={backslide4}
              slideChange={slide5}
            />
        {/* Slide 5 End */}
        
        {/* Slide 6 Start */}
        <div id="slide6" className={` ${state.slide6}`} style={{display: "none"}}>
          <DateOfBirth
            validation={register}
            validationMsg={errors}
            backClick ={backslide5}
            slideChange ={slide6}
            trigger={trigger}
            clearErrors={clearErrors}
            partnerclass = {state.partnerclass}
          />
        </div>
        {/* Slide 6 End */}
        
        {/* Slide 7 Start */}
        <div id="slide7" className={` ${state.slide7}`} style={{display: "none"}}>
          <BasicDetails
            backClick={backslide6}
            setError={setError}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            slideChange={slide7}
            getValues={getValues}
            partnerclass = {state.partnerclass}
          />

        </div>
        {/* Slide 7 End */}
        
        {/* Slide 8 Start */}
        <div id="slide8" className={` ${state.slide8}`} style={{display: "none"}}>
          <ContactDetails
                  validation={register}
                  validationMsg={errors}
                  setError={setError}
                  trigger={trigger}
                  getValues={getValues}
                  backClick={backslide7}
                  loaderClass={loaderClass}
                  disabled={btnDisabled}
                  formSubmit={formSubmit}
            />
        </div>
        {/* Slide 8 End */}
    </form>
    </>
  );
};

export default Form_Life_FSC_V1;