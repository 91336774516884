import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";


const Testimonials = () => {
    
  return (
    <>
    <section className="slide-section  ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-interval="10000">
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="sr-only">Previous</span> </a> <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="sr-only">Next</span> </a>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Extremely impressed with the complete…</h3>
                        <p>Extremely impressed with the complete service!! Spoke to a lady called Maddie from Smart Cover that explained everything so clearly that even knowing nothing myself prior could understand ! Would recommend to anyone that is thinking sensibly about their families future !!</p>
                        <p><b>Lewis Gardner</b></p>
                        <p><small><em>2 days ago</em></small></p>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Lofti Nemouchi </h3>
                        <p>The agent I have was such a lovely guy to talk to! Great accent and phone voice. He sounded very trustworthy and if I'm going to be honest I'd love to buy him a pint</p>
                        <p><b>Ryan Mawhinney</b></p>
                        <p><small><em>2 days ago</em></small></p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="row">
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Mathew was a lovely guy…..took under…</h3>
                        <p>Mathew was a lovely guy…..took under 30mins to get me all sorted and covered within my budget as well…..</p>
                        <p><b>customer</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/thegoldexchange.london?utm_medium=trustbox&utm_source=Mini" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Very happy and great customer service</h3>
                        <p>Very happy and great customer service</p>
                        <p><b>John Newport</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="row">
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Jade was great very friendly was going…</h3>
                        <p>Jade was great very friendly was going to ask her out she was that friendly we got on well</p>
                        <p><b>Kieran Yarker</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/thegoldexchange.london?utm_medium=trustbox&utm_source=Mini" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Superior Alex was very friendly</h3>
                        <p>Superior Alex was very friendly, knowledgeable and informative.. made the whole process easy. I will definitely recommend to all my friends and family.</p>
                        <p><b>H Petch</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="carousel-item ">
                  <div className="row">
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Everything had been explained very…</h3>
                        <p>Everything had been explained very clearly and understood! Very great advice and knowledge given which made me very happy to proceed further with my decision.<br/><br/>
                            All Thankyou to Simon!
                        </p>
                        <p><b>Paul Mccartney</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a href="https://uk.trustpilot.com/review/smartercover.co.uk" target="_blank">
                        <p> <img src="/assets/Life_FSC_V1/rating-5.png"/> </p>
                        <h3>Great service and help explained things…</h3>
                        <p>Great service and help explained things so they was clear to me and help me to choose for myself what was best for me and my family. Will definitely send people his way. Thanks superior Alex</p>
                        <p><b>Michael Goodwin</b></p>
                        <p><small><em>3 days ago</em></small></p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </section>
    </>
  );
};

export default Testimonials;