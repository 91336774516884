import React, {useEffect, useState} from "react";
import Email from "../../UI/Email";
import Telephone from "../../UI/Telephone";
import {useEmailValidation} from "../../../Hooks/useEmailValidation";
import {usePhoneValidation} from "../../../Hooks/usePhoneValidation";
// import Slide from '../../Includes/Slide';
import RadioButton from '../../UI/RadioButton';
// import PrivacyPolicy from "./PrivacyPolicyModal";
import { useQuestion } from "../../../Hooks/useQuestion";


const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  formSubmit
}) => {
  const { question } = useQuestion();
  const [showSlidepopup, setshowSlidepopup] = useState("hide");
  const [questionValidationMsg, setquestionValidationMsg] = useState();
  const {emailValidation}   = useEmailValidation();
  const {phoneValidation}   = usePhoneValidation();


// Basic Question get
const [clickedSlide, setClickedSlide] = useState([]);
const questionsid_list ="1";
const [questionlist, setquestionlist] = useState({});
const [radiolist, setradiolist] = useState();
const [selectedAnswer,setSelectedAnswer] = useState([]);



  const validateSlide4 = async (e) => {
    e.preventDefault();
    const values = getValues();
   
    await trigger("txtEmail");
    await trigger("txtPhone");
      if(await  phoneValidCheck() === 1 && await emailValidCheck() === 1){
        formSubmit();
      }
    
   
  }


  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
      if (phone !== "") {
        const getPhoneValidation      =    await phoneValidation(phone)
        if (getPhoneValidation.data.status_code === 0 || getPhoneValidation.data.status === "Fail") {
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          return 0;
        } else{
          return 1;
        }
      }else{
        return 0;
      }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
      if (email !== "") {
        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
          const getEmailValidation      =    await emailValidation(email)
          if (getEmailValidation.data.status_code === 0) {
            setError("txtEmail", {
              type: "manual",
              message: "Email Already Exist",
            });
            return 0;
          } else{
            return 1;
          }
        }else{
          setError("txtEmail", {
            type: "manual",
            message: "Invalid Email Address",
          });
          return 0;
        }
      }else{
        return 0;
      }
  };


// radio button changes


const questionslideChange = (e) => {
  setClickedSlide({ slide: e.target.name });
};
const question1validation =(e)=>{
  if(e.target.value == 2){
    setSelectedAnswer({answer:e.target.value});
    setquestionValidationMsg("");
  }else{
    setSelectedAnswer([]);
    setshowSlidepopup('show');
  }
}
const backpopup=()=>{
  setshowSlidepopup('hide');
}

let styleclass = "";
useEffect(() => {
  (async () => {
    const response = await question(questionsid_list);
    setquestionlist(response.data.response[0]);
    const options = response.data.response[0].answers;
    const questionId = response.data.response[0].questionId
    const radioList = options.map(option => {
      if(option.value == 2){
        styleclass = " check-no";
      }else{
        styleclass = " check-yes";
      }
      return( 
          <li className="clk_11" key={`question${questionId}_${option.value}`}>
            <label htmlFor={option.value}>
             
            <div className="smoker2">
                  <RadioButton 
                      value={option.value} 
                      name={`question${questionId}`} 
                      className="radio-button" 
                      labelName={option.label} 
                      labelClass="radio" 
                      onClick={question1validation} 
                      key={option.value}
                      labelClassName={styleclass}
                  />
              </div>
            </label>
      </li>
      )
  });
  setradiolist(radioList);
  })();
}, []);


useEffect(() => {
   
  if (clickedSlide.slide === "question1") {
    if(selectedAnswer.answer==="2"){
      setquestionValidationMsg("");
    }else{
      setshowSlidepopup('show');
    }
  }
}, [clickedSlide]);

  return (
    <>

<div className="col-lg-12 form_cont">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center nopad">
                <div className="row">
                    <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2 ">
                        <label className="spacead"> Email Address </label>
                        <Email
                          name="txtEmail"
                          className="form-control   add_prvcy"
                          placeholder="Email Address"
                          onBlur={emailValidCheck}
                          validation={validation({
                            required: "Please Enter Valid Email Address",
                            pattern: {
                              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Invalid Email Address",
                            },
                          })}
                          validationMsg={
                            validationMsg.txtEmail && validationMsg.txtEmail.message
                          }
                        ></Email>
                        <i className="tick fa_privcy" style={{ display: "none" }}></i>
                        <div className="input-group-append">
                        <span className="input-group-text">
                        <img src="/assets/Life_FSC_V1/privacy.png" alt="" className="privcy pull-right"/>
                        </span>
                        </div>
                        {validationMsg.txtEmail && (
                            <span
                              className="error_msg "
                              id="email_err"
                            >
                              {validationMsg.txtEmail.message}
                            </span>
                          )}
                    </div>
                    <div className=" offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 input-group form-group sizer2 ">
                        <label className="spacead">Phone Number</label>
                        <Telephone
                            name="txtPhone"
                            className="form-control  add_prvcy"
                            placeholder="Phone Number"
                            onBlur={phoneValidCheck}
                            onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                            validation={validation({ required: true })}
                            validationMsg={
                              validationMsg.txtPhone && "Please Enter Valid Phone Number"
                            }
                          ></Telephone>
                          <i className="tick fa_privcy" style={{ display: "none" }}></i>
                        <div className="input-group-append">
                        <span className="input-group-text">
                        <img src="/assets/Life_FSC_V1/privacy.png" alt="" className="privcy pull-right"/>
                        </span>
                        </div>
                        {validationMsg.txtPhone && (
                            <span
                              className="error_msg "
                              id="phone_err"
                            >
                              Please Enter Valid Phone Number
                            </span>
                          )}
                    </div>
                </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center p-0">
            <input
                name="formSubmitBtn"
                className="next-but btn my-bt "
                value="Compare Quotes >>"
                type="submit"
                disabled={disabled}
                onClick={validateSlide4}
              />
                <div className="col-12 text-center mt-1">
                    <a id="back7" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/>  Previous </a>
                </div>
                <div className="text-center safe-text"> 
                    <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/> Safe | Secure | Privacy Protected
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
                    <div className="consent text-center">
                        <p style={{color: "#000", paddingBottom: "15px"}}>By submitting your enquiry, you agree to our <a href="" data-toggle="modal" data-target="#privacypolicy">Privacy Policy </a>
                        and agree we may use the contact details provided to contact you about
                        your Life Insurance plan.
                        </p>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
    </>
  );
};
export default ContactDetails;
