import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";


const Testimonials2 = () => {
    
  return (
    <>
    <section className="fut">
      <div className="container">
        <div className="col-lg-12">
            <div className="text-center log">
              <a href="#"><img src="/assets/Life_FSC_V1/logo.jpg" alt="" className="footer_logo"/></a>
            </div>
            <p className="abt_txt text-center">
              <span style={{fontWeight: "500"}}>Smarter Cover Limited</span> is authorised and regulated by the Financial Conduct Authority and is entered on the Financial Services Register under reference 556053. <a rel="noopener" href="https://register.fca.org.uk/s/" target="_blank" title="https://register.fca.org.uk">https://register.fca.org.uk</a>
            </p>
            <p className="abt_txt text-center">Smarter Cover Limited is registered in England and Wales registered no. 07123871 with registered address:</p>
            <p className="abt_txt text-center">Smarter Cover Limited, 243 Brooklands Road, Weybridge, Surrey, KT13 0RH.</p>
            <p className="abt_txt text-center">* We do not charge you for our service as we are paid directly by the Insurer who we arrange your insurance with
            </p>
        </div>
      </div>
    </section>
    </>
  );
};

export default Testimonials2;