import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";
import SelectBox from "../../UI/SelectBox";
import { CoverAmount, CoverLength } from "../../../Constants/Constants";

const Slide4 = ({
  className,
  slideChange,
  backClick,
}) => {
 
  return (
    <>

        <div id="slide4" className={className} style={{display: "none"}}>
            <div className="col-lg-12 form_2">
            <div className="col-lg-12 row custom m-0">
                <p className="hdding py-2 spacead">How much cover would you like? </p>
                <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 txt-lft">
                  <SelectBox
                    className="form-control  not_chosen"
                    OptionValue={CoverAmount}
                    name="question7"
                  ></SelectBox>
                </div>
                <p className="hdding py-2 spacead">How long would you like the cover for?</p>
                <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12 txt-lft">
                  <SelectBox
                    className="form-control  not_chosen"
                    OptionValue={CoverLength}
                    name="question8"
                  ></SelectBox>
                </div>
            </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-12 col-sm-12 bord text-center">
            <input type="button" id="" name="" className="next-but next_04 btn my-bt sctop " onClick={slideChange} value="Continue >>"/>
            <a id="back3" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
            </div>
            <div className="text-center safe-text"> 
            <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
            </div>
        </div>
    </>
  );
};
export default Slide4;
