import React from "react";
import '../../Pages/Splits/Life_FSC_V1.scss'
import FbViewPixel  from '../../Includes/FbViewPixel';

const Header = () => {
  return (
    <>
    <FbViewPixel/>
     <header className="col-lg-12 col-md-12 col-sm-12 col-12 d-lg-block d-md-block d-sm-block d-none" id="page-top">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 text-center">
              <img src="/assets/Life_FSC_V1/logo.jpg" alt="" className="logo"/>     
            </div>
        </div>
      </div>
    </header>
    </>
  );
};

export default Header;
