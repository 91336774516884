import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";

const Slide2 = ({
  className,
  slideChange,
  classNameChecked3,
  classNameChecked4,
  classNameChecked5,
  classNameChecked6,
  classNameChecked7,
  classNameChecked8,
  classNameChecked9,
  backClick
}) => {
 
  return (
    <>

        <div id="slide2" className={className} style={{display: "none"}}>
            <div className="col-lg-12 form_2">
            <div className="col-lg-12 row custom m-0">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 txt-lft">
                <p className="hdding py-2">Type of cover</p>
                <div className="offset-lg-3 col-lg-6 col-12 text-center mt-1 nopad">
                    <ul className="icon_buttons">
                    <li className="mr js_me w100" id="testCheckbox" name="testCheckbox" style={{width:"100%"}}>
                        <label for="lev1" className="next_02 next-but sctop">
                            <div className={classNameChecked3}>
                              <input type="radio"  className="radio-button" name="question2" onClick={slideChange} id="lev1" value="3"/>
                              <p className="cover_text m9" id="checkbox_text">Level</p>
                            </div>
                        </label>
                    
                    </li>
                    <li className="mr w100" style={{width: "100%"}}>
                        <label for="dec" className="next_02 next-but sctop">
                            <div className={classNameChecked4}>
                            <input type="radio" className="radio-button" name="question2" onClick={slideChange} id="dec" value="4"/>
                            <p className="cover_text m9">Decreasing</p>
                            </div>
                        </label>
                        
                    </li>
                    </ul>
                </div>
                <p className="hdding py-2">Critical illness cover? </p>
                <ul className="icon_buttons">
                    <li className="mr js_me">
                    <label for="yourself-yes1" className="next_02 next-but">
                        <div className={classNameChecked5}>
                            <input type="radio"  className="radio-button" name="question3" onClick={slideChange} id="yourself-yes1" value="5"/>
                            <img className="original-image" src="/assets/Life_FSC_V1/hand_yes_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/hand_yes_white.png" alt="" /> 
                        </div>
                    </label>
                        <p className="cover_text">YES</p>
                    </li>
                    <li className="mr js_me">
                    <label for="yourself-no1" className="next_02 next-but sctop">
                        <div className={classNameChecked6}>
                            <input type="radio" className="radio-button" name="question3" onClick={slideChange} id="yourself-no1" value="6"/>
                            <img className="original-image" src="/assets/Life_FSC_V1/hand_no_blue.png" alt="" /> 
                            <img className="active-image" src="/assets/Life_FSC_V1/hand_no_white.png" alt="" /> 
                        </div>
                    </label>
                        <p className="cover_text">NO</p>
                    </li>
                </ul>
                <p className="hdding py-2">Premium type?</p>
                <div className="offset-lg-3 col-lg-6 col-12 text-center mt-1 nopad">
                <ul className="icon_buttons">
                    <li className="mr js_me w100" id="testCheckbox" name="testCheckbox" style={{width: "100%"}}>
                    <label for="pre" className="next_02 next-but sctop">
                        <div className={classNameChecked7}>
                            <input type="radio"  className="radio-button" name="question4" onClick={slideChange} id="pre" value="7"/>
                            <p className="cover_text m9" id="checkbox_text">Guaranteed</p>
                        </div>
                    </label>                            
                    </li>
                    <li className="mr w100" style={{width: "100%"}}>
                    <label for="pre1" className="next_02 next-but sctop">
                        <div className={classNameChecked8}>
                            <input type="radio" className="radio-button" name="question4" onClick={slideChange} id="pre1" value="8"/>
                            <p className="cover_text m9">Reviewable</p>
                        </div>
                    </label>
                    </li>
                    <li className="mr w100" style={{width: "100%"}}>
                    <label for="pre2" className="next_02 next-but sctop">
                        <div className={classNameChecked9}>
                            <input type="radio" className="radio-button" name="question4" onClick={slideChange} id="pre2" value="9"/>
                            <p className="cover_text m9">Don't Know</p>
                        </div>
                    </label>
                    
                    </li>
                </ul>
                </div>
            </div>
            </div>
            </div>
            <div className="col-12 text-center mt-1">
            <a id="back1" className="back_btn sctop" onClick={backClick}><img src="/assets/Life_FSC_V1/arrow.png" alt=""/> Previous </a>
            </div>
            <div className="text-center safe-text"> 
            <img src="/assets/Life_FSC_V1/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15"/>Safe | Secure | Privacy Protected
            </div>
        </div>
    </>
  );
};
export default Slide2;
