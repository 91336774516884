export const Salutation    =   [
    {
        label:"Select Title",
        value:""
    },
    {
        label:"Mr.",
        value:"Mr"
    },
    {
        label:"Mrs.",
        value:"Mrs"
    },
    {
        label:"Ms.",
        value:"Ms"
    },
    {
        label:"Dr.",
        value:"Dr"
    },
    {
        label:"Prof.",
        value:"Prof"
    },
];

export const PartnerSalutation    =   [
    {
        label:"Partner's Title",
        value:""
    },
    {
        label:"Mr.",
        value:"Mr"
    },
    {
        label:"Mrs.",
        value:"Mrs"
    },
    {
        label:"Ms.",
        value:"Ms"
    },
    {
        label:"Dr.",
        value:"Dr"
    },
    {
        label:"Prof.",
        value:"Prof"
    },
];

export const DobDay= [{label:"Day",value:''}];
const startDay = 1;
const endDay = 31;
for (var i=startDay;i<=endDay;i++) {
    var objDobDay = {};
    objDobDay["label"] = i;
    objDobDay["value"] = i;
    DobDay.push(objDobDay);
}

export const DobMonth= [
    { label:"Month", value:"" },
    { label:"January", value:"January" },
    { label:"February", value:"February" },
    { label:"March", value:"March" },
    { label:"April", value:"April" },
    { label:"May", value:"May" },
    { label:"June", value:"June" },
    { label:"July", value:"July" },
    { label:"August", value:"August" },
    { label:"September", value:"September" },
    { label:"October", value:"October" },
    { label:"November", value:"November" },
    { label:"December", value:"December" }
];

export const DobYear= [{label:"Year",value:''}];
const startYear = 2003;
const endYear = 1910;
for (var i=startYear;i>=endYear;i--) {
    var objDob = {};
    objDob["label"] = i;
    objDob["value"] = i;
    DobYear.push(objDob);
}

export const CoverAmount= [
    { label:"£20,000", value:"£20,000" },
    { label:"£40,000", value:"£40,000" },
    { label:"£60,000", value:"£60,000" },
    { label:"£80,000", value:"£80,000" },
    { label:"£100,000", value:"£100,000" },
    { label:"£120,000", value:"£120,000" },
    { label:"£140,000", value:"£140,000" },
    { label:"£160,000", value:"£160,000" },
    { label:"£180,000", value:"£180,000" },
    { label:"£1,100,000", value:"£1,100,000" },
    { label:"£1,120,000", value:"£1,120,000" },
    { label:"£1,140,000", value:"£1,140,000" },
    { label:"£1,160,000", value:"£1,160,000" },
    { label:"£1,180,000", value:"£1,180,000" },
    { label:"£1,200,000", value:"£1,200,000" },
    { label:"£1,220,000", value:"£1,220,000" },
    { label:"£1,240,000", value:"£1,240,000" },
    { label:"£1,260,000", value:"£1,260,000" },
    { label:"£1,280,000", value:"£1,280,000" },
    { label:"£1,300,000", value:"£1,300,000" },
    { label:"£1,320,000", value:"£1,320,000" },
    { label:"£1,340,000", value:"£1,340,000" },
    { label:"£1,360,000", value:"£1,360,000" },
    { label:"£1,380,000", value:"£1,380,000" },
    { label:"£1,400,000", value:"£1,400,000" },
    { label:"£1,420,000", value:"£1,420,000" },
    { label:"£1,440,000", value:"£1,440,000" },
    { label:"£1,460,000", value:"£1,460,000" },
    { label:"£1,480,000", value:"£1,480,000" },
    { label:"£1,500,000", value:"£1,500,000" }
];

export const CoverLength= [{label:"5 Years",value:'5'}];
const start = 6;
const end = 35;
for (var i=start;i<=end;i++) {
    var objLength = {};
    objLength["label"] = i+" Years";
    objLength["value"] = i;
    CoverLength.push(objLength);
}
