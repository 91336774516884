import {Api} from '../api/Api';

export const useEmailValidation = () => {

    const emailValidation = async (email) => {
        const getValidation = await Api.get(
            "api/v1/get-email-validation?email=" + email,
            {}
          );
        return getValidation;
    }
    return { emailValidation }
}