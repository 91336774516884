import React from "react";

const RadioButton = ({
  id,
  value,
  name,
  className,
  labelName,
  labelClassName,
  onClick,
  onLabelClick,
}) => {
  return (
    <>
      <input
        type="radio"
        className={className}
        name={name}
        value={value}
        id  ={value}
        onClick={onClick}
      />
      <label htmlFor={value} className={`radiolabel${labelClassName}`} onClick={onLabelClick}>
        {labelName}
      </label>
    </>
  );
};
export default RadioButton;
