import React from "react";
import "../../Pages/Splits/Life_FSC_V1.scss";
import Complaints from "./Complaints";
import Cookies from "./Cookies";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./TermsConditions";


const Footer = () => {
    
  return (
    <>
    <footer>
      <ul>
        <li><a href=""  data-toggle="modal" data-target="#privacypolicy">Privacy Policy</a></li>
        <li><a href=""  data-toggle="modal" data-target="#terms">Terms & Conditions</a></li>
        <li><a href=""  data-toggle="modal" data-target="#cookie">Cookie Policy</a></li>
        <li><a href=""  data-toggle="modal" data-target="#complaints">Complaints</a></li>
      </ul>
      <div className="container">
        <div className="row text-center justify-content-center">
            <div className="col-lg-10">
              <p>© Copyright Smarter Cover Limited 2021 . All Rights Reserved.</p>
            </div>
        </div>
      </div>
    </footer>
    <PrivacyPolicy />
    <Terms />
    <Cookies />
    <Complaints />
    </>
  );
};

export default Footer;